body {
  .site-header {
    &__nav {
      .site-header {
        &__utility-left--pc {
          display: block;
          position: absolute;
          top: 5px;
          width: 45%;
          padding-right: 2px;
          text-align: center;
          li {
            display: none;
          }
          .site-header {
            &__trustmark-wrapper {
              display: block;
              img {
                width: 50px;
                height: 50px;
              }
            }
          }
        }
        &__utility {
          margin-top: 7px;
        }
      }
    }
  }
  .sticky__live-chat__mobile {
    position: fixed;
    bottom: 30%;
    right: 3px;
    text-align: center;
    z-index: 1;
    .sticky-footer-chat {
      &__link {
        border: 1px solid $color-gray;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: block;
        background: $white;
      }
      &__img {
        vertical-align: top;
        margin-top: 4px;
        height: 14px;
      }
    }
  }
}

#site-content {
  .spp {
    &__container {
      .after-pay {
        &__spp {
          img {
            padding-bottom: 5px;
          }
        }
        &__pay-detail {
          font-size: 11px;
        }
        &__rate {
          display: inline;
          span {
            font-weight: 100;
          }
        }
      }
    }
  }
}
