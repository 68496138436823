//Address book
#settings,
#replenish {
  #colorbox {
    .address-overlay-shipping {
      .address-form {
        &__header {
          text-align: center;
          text-transform: uppercase;
        }
        &__item {
          width: 50%;
          float: left;
          padding-left: 12px;
          &.left {
            clear: left;
            padding: 0;
          }
          &.full {
            clear: left;
            width: 100%;
            padding: 0;
          }
          label {
            display: block !important;
          }
          input[type='text'],
          input[type='tel'] {
            width: 100%;
            padding: 10px 8px;
          }
          &.address_title_header,
          &.address_title_label {
            width: 100%;
            padding: 0;
            clear: left;
            label {
              display: inline-block;
            }
          }
          &.address_title_label {
            span {
              &.form-item {
                display: inline-block;
                margin-right: 20px;
                margin: 0 20px 0 0;
              }
            }
          }
          &.address-form {
            &__phone-1-container,
            &__phone-2-container {
              width: 100%;
              padding-left: 0;
            }
          }
        }
      }
      fieldset {
        &.qas {
          .qas_container {
            label {
              display: none !important;
              &.address_label {
                display: block;
              }
            }
          }
        }
        ul {
          &#form--errors--address {
            li {
              color: $color-error;
            }
          }
        }
      }
      .default_ship_container {
        margin-bottom: 15px;
      }
      #qas_search_target {
        .address-form {
          &__address1-container,
          &__address2-container {
            width: 100%;
            padding-left: 0;
          }
          &__state-container,
          &__postal-code-container,
          &__city-container {
            width: 31%;
            float: left;
            margin-right: 3.5%;
            clear: none;
          }
          &__state-container {
            padding: 0;
            label {
              display: none !important;
            }
          }
          &__postal-code-container {
            margin-right: 0;
          }
          &__address2-container {
            .js-placeholder-mode {
              & + lable {
                opacity: 0 !important;
              }
            }
          }
        }
        .text--form-help {
          display: inline-block;
          margin-bottom: 15px;
        }
      }
    }
    #cboxLoadedContent {
      .address-delete {
        .address-delete__header {
          text-transform: uppercase;
          text-align: center;
        }
        .button--secondary {
          &.address-delete__link {
            width: 49%;
            float: left;
            margin-right: 30px;
          }
        }
      }
      max-height: 550px;
    }
  }
  .ui-widget-content {
    &.ui-menu {
      height: 12em;
      overflow-y: auto;
    }
    a {
      text-decoration: none;
      border-bottom: 1px solid $color-lightest-grey;
    }
  }
  #address_form_container {
    position: relative;
  }
}
// Profile preferences
#profile_preferences {
  .profile-page__container--form {
    .profile-info__fieldset {
      .field-container {
        .label {
          display: none;
        }
      }
    }
  }
}
/* order history */
#orders {
  .orders-list {
    &__container {
      .order-details {
        .offer_container {
          .offer {
            width: 70%;
          }
        }
      }
    }
  }
}

// Replenishment
.replenishment-page {
  .account-page__header {
    .account-page__title {
      text-align: center;
      text-transform: uppercase;
      padding: 20px 0;
    }
  }
  &__content {
    .replenishment__products {
      .products-list__item {
        .product {
          padding-bottom: 20px;
          &__info {
            .product {
              &__name,
              &__frequency {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
  .replenishment-detail-page__content {
    .replenishment__products {
      > h5 {
        text-transform: uppercase;
      }
      .products-list__item {
        .product {
          padding-bottom: 20px;
          &__name,
          &__frequency {
            margin: 0;
            font-size: 13px;
          }
        }
      }
    }
    #replenishment_update {
      .shipping-frequency {
        &__info {
          p {
            margin-bottom: 1.5em;
          }
        }
        .frequency-table {
          padding: 0 0 1em 0;
          p {
            margin-bottom: 1em;
            font-size: 13px;
          }
        }
        .shipping-address {
          .address_controls__link {
            a {
              font-size: 13px;
            }
          }
        }
        .payment-info {
          .payment-method {
            h6 {
              text-transform: uppercase;
            }
            .payment-controls__list {
              li {
                font-size: 13px;
              }
            }
          }
        }
        .billing-address {
          &__header {
            text-transform: uppercase;
          }
        }
      }
      section {
        &.panel {
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }
}

//signin page
#signin {
  .new-account {
    .join-loyalty {
      text-align: #{$ldirection};
    }
    &__item_content {
      text-align: center;
    }
  }
}
