.site-content {
  .sd-appt-booking {
    .elc-appt-booking_confirmation-container {
      .Typography__Body2-sc-1hzsizk-7 {
        .elc-appt-booking_confirmation-virtual-location-zoom-link {
          span {
            word-break: break-all;
          }
        }
      }
    }
  }
}
