.checkout,
.viewcart {
  .checkout__content {
    .checkout-header {
      position: relative;
      padding: 1em;
      width: 100%;
      text-align: center;
      .checkout-header {
        &__title {
          display: inline-block;
          margin: 0;
          margin-bottom: 5px;
          padding: 0;
          text-transform: uppercase;
          font-size: 36px;
          line-height: 36px;
        }
      }
    }
    #viewcart-panel {
      .messages {
        padding: 0;
        padding-bottom: 8px;
        color: $color-error;
        background-color: transparent;
        border-width: 0;
        #error_cart\.empty {
          color: $color-black;
        }
      }
      .single-message:first-child {
        padding-top: 5px;
      }
    }
    .viewcart-buttons-panel {
      .continue-buttons {
        margin: 20px 0;
        padding: 0 0.5em;
        text-align: left;
        .go-shopping.btn {
          @include btn-style();
          min-width: 140px;
          width: auto;
          font-size: 11px;
          &:hover {
            @include btn-style-hover();
          }
        }
      }
    }
  }
  #top-viewcart-buttons,
  .tab-switcher,
  #recommended-products-panel {
    display: none;
  }
  .recommended-products-panel {
    &__title {
      font-size: 20px;
    }
  }
  .left {
    .guarantee-panel {
      display: none;
    }
    .signin-to-see-cart {
      margin-bottom: 15px;
    }
    .checkout-header {
      a {
        &.continue-shopping {
          display: none;
        }
      }
    }
  }
  .right {
    &.checkout__sidebar {
      border-top: none;
      .guarantee-panel,
      #bottom-viewcart-buttons {
        display: none;
      }
      .edit {
        font-size: 15px;
      }
      .panel {
        position: relative;
        margin: 0.2em 1em;
      }
      #offer-code-panel {
        margin: 0 1em;
        padding: 1em 0;
        border-bottom: 1px solid $color-light-gray;
        input {
          width: 100%;
          height: 47px;
          min-width: 100%;
        }
        .offer-code {
          &__input {
            margin-bottom: 10px;
            width: 100%;
          }
        }
        a {
          font-size: 16px;
          line-height: 1.6;
          color: $color-dark-gray;
          border-bottom: 1px solid $color-dark-gray;
          text-decoration: none;
          text-transform: uppercase;
          transition: color 0.3s, border-color 0.3s;
          cursor: pointer;
          letter-spacing: 0.075em;
          &:hover {
            text-decoration: none;
            color: $color-green;
            border-color: $color-green;
          }
        }
        label {
          &.offer-code__byline {
            display: none;
          }
        }
      }
      #need-help-panel {
        margin: 0 1em;
        padding: 1em 0;
        border-bottom: 1px solid $color-light-gray;
      }
      #links-panel {
        .overlay-link {
          &.js-max-purchase-policy {
            border-bottom: 1px solid $color-dark-gray;
            text-transform: uppercase;
          }
        }
      }
    }
    .link {
      border-bottom: transparent;
      margin: 0 0 1em 0;
    }
  }
  .tab-bar {
    display: block;
    overflow: hidden;
    border-bottom: 2px solid $color-dark-gray;
    margin-bottom: 16px;
    padding-bottom: 16px;
    margin-left: 0;
    li {
      margin-right: 0em;
      font-size: 20px;
      width: 28%;
      &:first-child {
        width: 29%;
      }
      &:last-child {
        width: 42%;
      }
      a {
        color: $color-light-gray;
        background-color: transparent;
        padding: 0;
        &.active {
          background-color: transparent;
          color: $color-dark-gray;
        }
      }
    }
  }
  .recommended-product-items {
    .description {
      .info {
        clear: both;
      }
    }
    .price-range {
      clear: both;
    }
  }
  #order-summary-panel {
    padding: 1em 0;
    margin: 0em 1em;
    border-bottom: 1px solid $color-light-gray;
    header {
      position: relative;
      margin: 0.5em 0;
      padding: 0;
      width: 100%;
    }
    .order-summary {
      &__content {
        .label {
          float: left;
          width: 65%;
          clear: both;
          margin-bottom: 0.5em;
        }
        .value {
          float: right;
          width: 35%;
          text-align: right;
          margin-bottom: 0.5em;
          font-weight: 700;
        }
        .total {
          margin-top: 0.5em;
          font-weight: 700;
        }
      }
      &__shipping-value {
        margin-bottom: 1em;
      }
      &__shipping-content {
        width: 60%;
      }
    }
  }
  .overlay-link,
  .link a {
    text-decoration: none;
    &:hover {
      color: $color-green;
      border-color: $color-green;
      text-decoration: none;
    }
  }
  .overlay-link {
    font-size: 16px;
    line-height: 1.6;
    color: $color-dark-gray;
    border-bottom: 1px solid $color-dark-gray;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.3s, border-color 0.3s;
    cursor: pointer;
    letter-spacing: 0.075em;
    &.js-max-purchase-policy {
      text-transform: none;
      border-bottom: dotted 1px;
    }
  }
  .checkout-password-label {
    display: none;
  }
}

.btn:hover {
  @include btn-style-hover();
}

#viewcart,
#index.checkout,
#samples {
  .site-footer {
    &__bottom {
      display: none;
    }
    &__bottom-condensed {
      display: block;
    }
    &__ack {
      display: none;
    }
    .social-media-links,
    .basic-noderef,
    .menu-ref {
      display: none;
    }
  }
}

body {
  &#viewcart {
    #recommended-products-panel {
      .recommended-products-panel__content {
        .recommended-product-items {
          .recommended-item {
            .description {
              width: 100%;
              margin: 0 15%;
            }
            a {
              text-decoration: none;
            }
            .quickshop {
              position: relative;
              top: 20px;
              margin: 0 15%;
            }
          }
          .price-range {
            margin: 0 15% 5% 15%;
          }
          .addtobag {
            margin: 0 15%;
          }
        }
      }
    }
    .generic-helplinks-overlay {
      &-shippinginfo,
      &-paymentinfo,
      &-returns-policy,
      &-purchase-policy {
        .customer-service {
          &__navigation,
          &_anchor,
          &__title,
          &__contact_info,
          &__menu-mob {
            display: none;
          }
        }
      }
    }
    // BOGO OfferCode Styles
    .bogo-offer {
      .cart-item {
        &__replenishment,
        &__remove-form,
        &__add-to-favorites {
          display: none;
        }
      }
    }
  }
}

.checkout__panel-title {
  display: block;
  cursor: pointer;
  margin: 0;
  margin-bottom: 0.5em;
  padding: 0;
  font-size: 17px;
  line-height: 36px;
  text-transform: uppercase;
  .toggle-icon {
    float: right;
    margin-top: 5px;
    &.icon--minus {
      @include icon('minus');
      margin-top: 0px;
    }
  }
}

//Viewcart panel
#viewcart-panel {
  overflow: hidden;
  position: relative;
  padding: 0;
  .viewcart-panel__content {
    .cart-header__title {
      padding: 0.8em 0;
      float: left;
      box-sizing: border-box;
      text-transform: uppercase;
      font-weight: 700;
      &--products {
        width: 46%;
        padding-left: 1.5em;
      }
      &--price {
        width: 18%;
        text-align: left;
        padding-left: 0;
      }
      &--qty {
        width: 13%;
        text-align: left;
        padding-left: 0;
      }
      &--total {
        width: 20%;
        float: right;
        text-align: center;
        padding-left: 0;
        box-sizing: border-box;
        text-transform: uppercase;
        font-weight: 700;
      }
    }
    .cart-item {
      padding: 28px 0;
      position: relative;
      margin: 0;
      box-sizing: border-box;
      border-top: 1px solid $color-lightest-grey;
      &:last-child {
        border-bottom: 1px solid $color-lightest-grey;
      }
      &__size {
        display: inline-flex;
      }
      &__price {
        text-align: left;
        float: left;
        width: 18%;
      }
      &__qty {
        width: 13%;
        clear: both;
        padding-top: 5%;
        padding-left: 23%;
        float: left;
        .selectBox-dropdown {
          min-width: 70px;
        }
        .qty-label {
          display: none;
        }
      }
      &__total {
        width: 17%;
        text-align: left;
        float: right;
        font-weight: 700;
        padding-top: 7%;
      }
      .cart-item {
        &__remove-form {
          .remove_link {
            border-bottom: 1px solid $color-dark-gray;
            text-transform: uppercase;
            text-decoration: none;
            position: absolute;
            top: 0px;
            right: 0;
            border: 0;
            font-size: 12px;
          }
        }
        &__product-name,
        &__product-subname {
          width: 80%;
          a {
            text-decoration: none;
          }
        }
      }
      .remove-sample {
        border-bottom: 1px solid $color-dark-gray;
        text-transform: uppercase;
        font-size: 11px;
        text-decoration: none;
      }
      &__thumb {
        padding: 0;
        float: left;
        width: 22%;
        margin-right: 2%;
        #index & {
          width: 35%;
          margin-right: -100%;
          clear: none;
          padding-right: 1em;
        }
      }
      .product {
        &__desc {
          #index & {
            position: relative;
            width: 65%;
            float: right;
            margin-left: 0;
            margin-right: 0;
            padding-right: 0;
            clear: right;
          }
        }
      }
      .hazmat {
        float: left;
        padding-left: 23%;
      }
    }
    .cart-item__desc-info {
      float: left;
      position: relative;
      width: 75%;
      margin-bottom: 0;
      padding-right: 1%;
      .cart-item__price {
        display: none;
      }
      .cart-item__remove-form {
        .cart-item__add-to-favorites {
          display: none;
        }
      }
      .replenishment {
        &.cart-item__replenishment {
          float: none;
          width: 200px;
          padding-top: 20px;
          position: relative;
        }
        .replenishment__description,
        .replen_notice {
          display: none;
        }
      }
    }
  }
}

// Information pop ups
#viewcart {
  .generic-helplinks-overlay {
    &-shippinginfo,
    &-paymentinfo,
    &-returns-policy,
    &-purchase-policy {
      #cboxLoadedContent {
        max-height: 500px;
        overflow: auto;
      }
    }
  }
  .overlay-addclass-generic-helplinks-overlay {
    &-shippinginfo,
    &-paymentinfo,
    &-returns-policy,
    &-purchase-policy {
      width: 630px;
      height: 550px;
    }
  }
  #colorbox {
    &.generic-helplinks-overlay {
      &-shippinginfo,
      &-paymentinfo,
      &-returns-policy,
      &-purchase-policy {
        width: 630px;
        height: 550px;
        max-height: 560px;
      }
    }
  }
}
/* samples */
#samples {
  .viewcart-buttons-panel,
  .samples-buttons {
    padding: 0px 10px;
  }
  #samples-panel {
    header {
      padding: 2em 1em 0em 1em;
      text-align: center;
      .samples-panel {
        &__title {
          line-height: normal;
          font-size: 15px;
          margin-bottom: 0;
        }
      }
    }
  }
}

// Checkout signIn
.checkout {
  &.active-panel-signin {
    section {
      #shipping-panel,
      #order-summary-panel,
      #payment-panel {
        display: none;
      }
    }
    .sign-in-panel {
      .new-or-returning-radios {
        label {
          margin: 10px 0;
          display: block;
          line-height: 20px;
          text-transform: uppercase;
        }
      }
      .checkout__new-account,
      .checkout__return-user {
        .checkout__subtitle {
          text-transform: uppercase;
          margin: 10px 0;
        }
        form {
          input[type='submit'] {
            margin: 15px 0;
            width: 100%;
          }
        }
      }
      .messages {
        padding: 0;
        padding-bottom: 8px;
        color: $color-error;
        background-color: transparent;
        border-width: 0;
        [class*='error_offer_criteria_'] {
          display: none;
        }
        .single-message {
          color: $color-error;
        }
      }
    }
    .right {
      &.checkout {
        &__sidebar {
          &.display-footer {
            display: none;
          }
        }
      }
    }
  }
  &.active-panel-payment {
    &#index {
      .right {
        &.checkout__sidebar {
          display: block;
          #shopping-bag-panel,
          #viewcart-panel {
            display: none;
          }
        }
      }
    }
  }
  &.active-panel-shipping,
  &.active-panel-registration {
    &#index {
      .right {
        &.checkout__sidebar {
          display: block;
          #need-help-panel,
          #links-panel {
            display: none;
          }
          #shopping-bag-panel {
            margin: 20px 0 0 0;
            .shopping-bag-panel {
              &__title {
                line-height: 1em;
                float: left;
              }
              &__item-count {
                line-height: 0.5em;
                float: right;
                padding: 0.2em 0;
                .item-count__edit {
                  text-transform: uppercase;
                }
              }
            }
          }
          &.display-footer {
            #shopping-bag-panel {
              display: none;
            }
            #need-help-panel,
            #links-panel {
              display: block;
            }
          }
        }
      }
    }
  }
  &__index-content {
    .checkout {
      &__sidebar {
        &.display-footer {
          display: block;
        }
      }
    }
  }
  .checkout-registration {
    input[name='PASSWORD'].js-label-mode + label:before {
      content: attr(alt);
    }
    .example-user-email {
      font-weight: 700;
    }
  }
}
/* index page */
#index {
  &.checkout {
    padding: 0;
    .messages {
      color: $color-error;
      background-color: transparent;
      border-width: 0;
      padding: 0 0 15px 0;
      .single-message {
        color: $color-error;
      }
    }
    .form-item {
      .invalid_marker {
        position: absolute;
        right: 15px;
        bottom: 12px;
        color: $color-error;
      }
      &.title {
        .invalid_marker {
          display: none;
        }
      }
      .valid_marker {
        font-size: 2em;
        position: absolute;
        right: 18px;
        bottom: 12px;
        &:before {
          font-size: 19px;
          font-family: icons;
          color: $color-black;
          display: inline-block;
          vertical-align: middle;
          line-height: 1;
          font-weight: 400;
          font-style: normal;
          speak: none;
          text-decoration: inherit;
          text-transform: none;
          text-rendering: optimizeLegibility;
          -webkit-font-smoothing: antialiased;
        }
      }
    }
    .checkout__subtitle {
      text-transform: uppercase;
    }
    .signin-panel,
    #shipping-panel {
      overflow: hidden;
      position: relative;
      padding: 1em 0;
      .edit-controls {
        .checkout__panel-title {
          width: 100%;
          float: left;
          a {
            border: none;
            font-size: 14px;
            margin-bottom: 15px;
            font-weight: bold;
            padding: 0;
            float: left;
            &:hover {
              background: none;
              border-color: none;
              color: $color-green;
            }
          }
        }
        a {
          float: right;
          display: inline-block;
          margin-top: -2px;
          text-transform: uppercase;
          border-radius: 4px;
          padding: 13px 15px 14px 15px;
          line-height: 1.5;
          font-size: 11px;
          letter-spacing: 0.05em;
          text-decoration: none;
          text-align: center;
          cursor: pointer;
          vertical-align: middle;
          min-width: 110px;
          border: 1px solid $color-dark-gray;
          background: $color-white;
          color: $color-dark-gray;
          border-color: $color-lighter-gray;
          &:hover {
            text-decoration: none;
            background: $color-green;
            color: $color-white;
            border-color: $color-green;
          }
        }
      }
      input[type='text'],
      input[type='tel'] {
        width: 100%;
      }
      .continue-button-wrapper {
        input[type='submit'] {
          width: 100%;
        }
      }
      .name-fields {
        .first-name {
          margin-bottom: 20px;
          display: inline-block;
          width: 49%;
        }
        .last-name {
          margin-bottom: 20px;
          display: inline-block;
          width: 49%;
          float: right;
        }
      }
      .phones,
      .title-fields,
      .qas {
        .form-item {
          margin-bottom: 20px;
          &.title {
            width: 100%;
          }
        }
      }
      .billing-address-form-content {
        margin: 0 0 20px 0;
        input[type='radio'] ~ label {
          display: block;
          margin: 15px 0 0 0;
        }
      }
      .edit {
        &-shipping-radio,
        &-billing-radio {
          margin: 20px 0;
          .radio {
            margin-right: 20px;
            display: inline-block;
            margin-bottom: 5px;
          }
        }
      }
      .gift-options__content {
        .giftwrap__is-gift {
          margin: 15px 0 15px 0;
        }
        #gift-max-char {
          margin-bottom: 20px;
        }
        .gift-options {
          > img {
            display: none;
          }
        }
        .card-message {
          textarea[data-error] {
            border: solid 1px $color-error;
          }
          &-to,
          &-from {
            width: 48%;
            display: inline-block;
            input[data-error] {
              border: solid 1px $color-error;
            }
          }
          &-from {
            float: right;
            clear: both;
          }
        }
      }
      .email-and-sms-promotions {
        margin-bottom: 20px;
      }
      .addresses,
      .city-state-zip {
        .form-item {
          margin-bottom: 20px;
        }
      }
      .city-state-zip {
        .form-item {
          display: inline-block;
          margin-right: 1.5%;
          &.city {
            width: 34%;
          }
          &.state {
            width: 30%;
          }
          &.postal-code {
            width: 30%;
            margin-right: 0;
            float: right;
          }
        }
      }
      .qas_container {
        input[name='qas_search'] {
          height: 1em;
          padding: 20px 15px;
        }
      }
    }
    #shipping-panel {
      padding: 0;
      &.adpl {
        input[type='radio'] {
          &:required {
            &:invalid {
              &:not(:checked) {
                &.checkedEmpty {
                  & + label {
                    span {
                      color: $color-error;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .review-panel,
    .samples-page,
    .shipping-panel {
      #continue-btn,
      .review-submit,
      .samples-panel__continue-checkout--button {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 0 15px 15px 15px;
        background: $color-white;
        z-index: 100;
      }
    }
    .checkout__sidebar {
      #viewcart-panel {
        &.panel {
          margin: 0.2em 0em;
        }
      }
      &.display-footer {
        display: none;
      }
    }
    #registration-panel {
      padding: 1em;
      .how-will-we-use {
        float: none;
        width: auto;
        padding: 0;
        border: none;
        .checkout__subtitle {
          text-transform: none;
        }
      }
      .form-item {
        margin-bottom: 20px;
        &.create-account {
          position: fixed;
          bottom: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          text-align: center;
          margin-bottom: 10px;
          input {
            border: 1px solid $color-white;
            width: 90%;
          }
        }
        &.email-address {
          margin-bottom: 10px;
        }
      }
      header {
        margin: auto;
        padding: 15px 0 0 0;
        border-top: 1px solid $color-light-gray;
        border-bottom: 1px solid $color-light-gray;
      }
      &.collapsed {
        padding-bottom: 0;
        padding: 0;
        header {
          padding: 0;
          border-top: none;
          border-bottom: none;
        }
      }
      &.finished,
      &.edit.active {
        a#registration-panel-edit {
          float: right;
          display: inline-block;
          margin-top: -2px;
          text-transform: uppercase;
          border-radius: 4px;
          padding: 13px 15px 14px 15px;
          line-height: 1;
          font-size: 11px;
          letter-spacing: 0.05em;
          text-decoration: none;
          text-align: center;
          cursor: pointer;
          vertical-align: middle;
          min-width: 110px;
          border: 1px solid $color-dark-gray;
          background: $color-white;
          color: $color-dark-gray;
          border-color: $color-lighter-gray;
          &:hover {
            text-decoration: none;
            background: $color-green;
            color: $color-white;
            border-color: $color-green;
          }
        }
        header {
          padding-bottom: 15px;
          float: left;
          width: 100%;
        }
        .registration-panel {
          &__title {
            margin-bottom: 0;
            a#registration-panel-edit {
              border: none;
              font-size: 14px;
              margin-bottom: 15px;
              font-weight: 700;
              padding: 0;
              float: left;
              padding-top: 10px;
            }
          }
        }
      }
    }
  }
  &.active-panel-signin {
    .checkout {
      .checkout__content {
        section {
          &.shipping-panel,
          &.payment-panel {
            &.collapsed {
              display: none;
            }
          }
        }
      }
    }
  }
}
/* order confirmation */
#confirm {
  &.checkout {
    max-width: none;
    padding: 0;
    .checkout {
      &__content {
        #registration-panel {
          .show-password {
            display: none;
          }
        }
      }
    }
    .overlay-link {
      text-transform: initial;
      letter-spacing: normal;
      font-size: 15px;
    }
    .site-footer {
      padding-bottom: 60px;
    }
  }
  .sign-in-confirmation-panel {
    padding: 1em;
    .form-item {
      margin-bottom: 20px;
      &.submit {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
        input {
          width: 90%;
          border: 1px solid $color-white;
        }
      }
    }
    &__title {
      margin: auto;
      padding: 15px 0 0 0;
      border-top: 1px solid $color-light-gray;
      border-bottom: 1px solid $color-light-gray;
      font-size: 14px;
    }
  }
  .confirmation-panel {
    &__container {
      padding: 1em;
      #confirmation-panel {
        .checkout-header {
          font-size: 14px;
          margin-bottom: 0.5em;
          text-align: center;
        }
        .confirmation-panel {
          &__content {
            width: 100%;
            background-color: $color-white-smoke;
            padding: 5px;
            margin: auto;
            margin-bottom: 20px;
            font-weight: 700;
            text-align: center;
            .confirmation-panel {
              &__order-number {
                #confirm_trans_order_id {
                  color: $color-green;
                  border-bottom: none;
                  font-size: 16px;
                  cursor: pointer;
                  a {
                    color: $color-green;
                    border-bottom: none;
                  }
                }
              }
            }
          }
          &__actions {
            margin-left: 0;
            .receipt-print-btn {
              @include btn-style();
              width: 100%;
              font-size: 11px;
              &:hover {
                text-decoration: none;
                background: $color-green;
                color: $color-white;
                border-color: $color-green;
              }
            }
          }
        }
      }
    }
  }
  #confirmation-page {
    .responsive-container {
      padding: 0em 1em 1em 1em;
      .registration-panel {
        &__title {
          font-size: 14px;
          text-transform: none;
        }
        header {
          border-top: 1px solid $color-light-gray;
          border-bottom: 1px solid $color-light-gray;
          padding: 1em 0em 0em 0em;
        }
        #continue-btn {
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          margin: 0;
          padding: 0 15px 15px 15px;
          z-index: 1;
          input {
            width: 100%;
            border: 1px solid $color-white;
          }
        }
      }
      #need-help-panel,
      #links-panel {
        margin: 0;
      }
    }
  }
}

.registration-panel {
  #completed_checkout_registration,
  #checkout_registration_panel {
    .how-we-will-use-container {
      .how-will-we-use {
        ul {
          list-style: disc;
          margin-left: 15px;
        }
        .checkout__subtitle,
        .pre {
          margin-left: 20px;
        }
      }
    }
  }
}

.wp-samples-panel,
.wp-samples-landing-panel {
  .checkout__panel-title,
  .offer-samples {
    text-align: center;
  }
  .add-button,
  .add-cart {
    display: block;
    float: right;
    position: fixed;
    bottom: 0;
    z-index: 10;
    width: 95%;
    margin-bottom: 10px;
    border: 1px solid $white;
    border-radius: 4px;
  }
  .return-link {
    display: none;
  }
}

.offerspick-action-buttons {
  display: none;
}
